import Script from "next/script"

export const CookieScript = ({
  cookieScriptId,
}: {
  cookieScriptId: string
}) => {
  return (
    <Script
      src={`//geo.cookie-script.com/s/${cookieScriptId}.js?country=?country=at-be-bg-hr-cy-cz-dk-ee-fi-fr-de-gr-hu-ie-it-lt-lv-lu-mt-nl-pl-pt-ro-sk-es-si-no-is-ch-li-se`}
      type="text/javascript"
    />
  )
}
