import Script from "next/script"

interface GoAffProProps {
  goAffProId: string
}

export const GoAffPro = ({ goAffProId }: GoAffProProps) => {
  return (
    <Script
      id="goaffpro-script"
      src={`https://api.goaffpro.com/loader.js?shop=${goAffProId}`}
    />
  )
}
