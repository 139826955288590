import(/* webpackMode: "eager" */ "/opt/build/repo/apps/anchor/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/(general)/layout.tsx\",\"import\":\"Roboto_Flex\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/packages/anchor/src/components/BtnBuy/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/anchor/src/components/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/opt/build/repo/packages/anchor/src/components/Header/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/devtools/index.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/Price.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/i10n/src/RegionSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrackingScripts","Gorgias"] */ "/opt/build/repo/packages/tracking/src/loadScripts.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/cleanStaleCartId.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/clearRandomIdsFromBundles.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDetectDevice.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentReady.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useDocumentVisible.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useLocalStorage.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useOutsideClickCapture.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useScroll.ts");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/utils/src/useWindowWidth.tsx");
