"use client"

import { useEffect, useState } from "react"
import Script from "next/script"
import { isBrowser, STAGE } from "@local/utils"
import { usePathname } from "next/navigation"

export const Gorgias = ({ id }: { id?: string | null }) => {
  const [loaded, setLoaded] = useState(false)
  const pathname = usePathname()

  const GORGIAS_IDS = id ? id.split(",") : null

  const GORGIAS_ID =
    GORGIAS_IDS && GORGIAS_IDS.length > 0 ? GORGIAS_IDS[0] : null

  useEffect(() => {
    if (isBrowser() && GORGIAS_ID) {
      setLoaded(true)
    }
  }, [GORGIAS_ID])

  const verifyPathnameToHideGorgias = (pathname: string) => {
    const gorgiasChatElement = document.getElementById("gorgias-chat-container")

    if (!gorgiasChatElement) return

    if (pathname.includes("contact-us")) {
      gorgiasChatElement.setAttribute("style", "display: none")
    } else {
      gorgiasChatElement.setAttribute("style", "display: block")
    }
  }

  useEffect(() => {
    verifyPathnameToHideGorgias(pathname)
  }, [pathname])

  if (process.env.NEXT_PUBLIC_CHANNEL_ID === "baerskintactical") {
    return (
      <Script id="voiceflow-widget" type="text/javascript">
        {`
  (function(d, t) {
      var v = d.createElement(t), s = d.getElementsByTagName(t)[0];
      v.onload = function() {
        window.voiceflow.chat.load({
          verify: { projectID: '67461eeb90942a85d2ca8977' },
          url: 'https://general-runtime.voiceflow.com',
          versionID: '${STAGE === "stg" ? "staging" : "production"}'
        });
      }
      v.src = "https://cdn.voiceflow.com/widget/bundle.mjs"; v.type = "text/javascript"; s.parentNode.insertBefore(v, s);
  })(document, 'script');
  `}
      </Script>
    )
  }

  if (!loaded) {
    return null
  }

  return (
    <Script
      id="gorgias-chat-widget-install-v3"
      src={`https://config.gorgias.chat/bundle-loader/${GORGIAS_ID}`}
      strategy="afterInteractive"
      onLoad={async () => {
        window.GorgiasChat
          ? await window.GorgiasChat.init()
          : await new Promise(function (resolve) {
              window.addEventListener("gorgias-widget-loaded", function () {
                window.GorgiasChat.hidePoweredBy(true)
                //@ts-ignore
                resolve()
              })
            })
        // NECESSARY TO RUN VERIFICATION ON AFTER LOADING FIRST TIME
        verifyPathnameToHideGorgias(pathname)
        // if (conv) {
        //   //@ts-ignore
        //   initGorgiasChatPromise.then(async () => {
        //     window.GorgiasChat.open()
        //   })
        // }
      }}
    />
  )
}
