import { useEffect, useLayoutEffect, useState } from "react"
import debounce from "lodash.debounce"

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  const updateSize = (): void => {
    setIsMobile(window.innerWidth < 768)
  }

  useEffect(() => {
    updateSize()
  })

  useLayoutEffect(() => {
    window.addEventListener("resize", debounce(updateSize, 250))
    // updateSize()
    return (): void => window.removeEventListener("resize", updateSize)
  }, [])

  return isMobile
}

export default useIsMobile
