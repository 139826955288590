"use client"
import {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from "react"
import { classNames } from "@local/utils/src/classNames"
import styles from "./styles.module.css"

interface CommonProps {
  children: ReactNode
  className?: string
  disabled?: boolean
  full?: boolean
  id?: string
  isLoading?: boolean
  style?: CSSProperties
}

export interface ButtonProps extends CommonProps {
  onClick?: MouseEventHandler<HTMLButtonElement>
}

const Loader = () => {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((progress) => (progress === 100 ? 0 : progress + 1))
    }, 100)
    return () => clearInterval(interval)
  })

  return (
    <span className={styles.Loader}>
      <span
        className={styles.Loader__Inner}
        style={{
          width: `${progress.toString()}%`,
        }}
      />
    </span>
  )
}

const Button = ({
  children,
  className,
  disabled,
  full,
  id,
  isLoading,
  onClick,
  style,
}: ButtonProps) => {
  return (
    <button
      id={id}
      className={classNames(
        full ? styles.Button__Full : styles.Button,
        className ? className : ""
      )}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {children}
      {isLoading && <Loader />}
    </button>
  )
}

interface AnchorButtonProps extends CommonProps {
  href: string
}

const AnchorButton = forwardRef<HTMLAnchorElement, AnchorButtonProps>(
  ({ children, className, full, href, id, isLoading, style }, ref) => {
    return (
      <a
        id={id}
        href={href}
        className={classNames(
          full ? styles.Button__Full : styles.Button,
          className ? className : ""
        )}
        ref={ref}
        style={style}
      >
        {children}
        {isLoading && <Loader />}
      </a>
    )
  }
)
AnchorButton.displayName = "AnchorButton"

export { Button, AnchorButton }
