import Script from "next/script"
import { getTrackingCookies } from "../getTrackingCookies"
import { ConversionProps } from "./types"
import { captureException } from "@sentry/nextjs"
import { isBrowser } from "@local/utils/src/isBrowser"

interface MetaProps {
  metaPixelId: string
}

export const Meta = ({ metaPixelId }: MetaProps) => {
  const { gtmUpi } = getTrackingCookies()
  const metaPixelData = metaPixelId.split("_")

  return (
    <>
      <Script
        id="metapixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${metaPixelData[0]}', {'extern_id': '${gtmUpi}'});
      fbq('track', 'PageView');
`,
        }}
      />
      <noscript>
        {/* eslint-disable-next-line jsx-a11y/alt-text, @next/next/no-img-element */}
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src={`https://www.facebook.com/tr?id=${metaPixelData[1]}&ev=PageView&noscript=1`}
          alt=""
        />
      </noscript>
    </>
  )
}

interface MetaConversionProps extends ConversionProps {
  productIds?: Array<string>
}

export const fbqConvert = ({
  value,
  currency,
  id,
  productIds,
}: MetaConversionProps) => {
  try {
    if (window && window.fbq) {
      window.fbq(
        "track",
        "Purchase",
        {
          content_ids: productIds,
          content_type: "product",
          currency: currency,
          transaction_id: id,
          value: Math.round(value * 0.6).toString(),
        },
        { eventID: id }
      )
    }
  } catch (e) {
    console.error("fbq not loaded")
    captureException("Could not send fbq conversion")
  }
}

export const trackFbq = (name: string, data?: any) => {
  if (isBrowser() && window.fbq) {
    window.fbq("track", name, data)
  }
}
